/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


import './App.css'

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';



function currentYear() {
    return new Date().getFullYear();
}

export default function Service() {


    return (
            <div className="container">
                <Row>
                    <Col lg={3} xs={12}>
            
                    </Col>
            
                    <Col lg={9} xs={12}>
                    <h4>myBusinessPartner est un réseau d'apporteurs d'affaire et de solutions à votre service&nbsp;!</h4>
                    <p><br/></p>        
                    <p className="leads">Ce projet permet de créer un réseau de réseaux qui va s'agrandir au fil du temps. </p>
                    <p>Ainsi chaque apporteur d'affaire peut puiser dans le réseau des autres, s'il n'a pas la solution dans le sien. Aucune affaire n'est perdue.</p>
                    <p>Nous nous mettons à l'écoute de votre savoir-faire et nous prenons le temps nécessaire. Ainsi nous pouvons le faire savoir dans notre réseau.</p>
                    <p>Nous sommes également à l'écoute de vos besoins et trouverons auprès de nos partenaires, les ressources ou le savoir-faire qui pourront vous apporter la solution.</p> 
                    <p>Le projet n'a d'intérêt que s'il fonctionne dans les deux sens. Retrouvez-nous dans les principaux événements de résautage de la région, et prenons le temps de faire connaissance. </p>
                    </Col>
                </Row>
                <Row>
                    <Col lg={3} xs={12}/>
                    <Col lg={9} xs={12}>
                    Vous êtes apporteur d'affaire, travaillez de façon autonome et vous habitez en Suisse romande. Venez me rejoindre&nbsp;!
                    </Col>
                </Row>
                <Row/>
                <footer>
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <p class="copyright text-muted small">Copyright &copy; myBusinessPartner 2023 - {currentYear()} <span class="text-nowrap">All Rights Reserved</span></p>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
            )
}
