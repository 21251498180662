
/*
 import Col from 'react-bootstrap/Col';
 import Row from 'react-bootstrap/Row';
 import Badge from 'react-bootstrap/Badge';
 import Card from 'react-bootstrap/Card';
 */


import bmc from './pics/BMCFR.png';
import './App.css'


function currentYear() {
    return new Date().getFullYear();
}

export default function Welcome(props) {

return (
        <div className="container">
    <p style={{marginTop:'20px'}}>
        <span class="test-nowrap">myBusinessPartner</span> est un service qui permet de mettre en relation des entreprises romandes ayant des besoins avec des sociétés pouvant apporter une solution. Ce projet fonctionne comme apporteur de solution pour les uns et apporteur d'affaire pour les autres selon le business model canevas ci-dessous. Les uns devenant les autres au fil du temps. Ainsi chacun gagne à rejoindre ce projet. 
    </p> 

    <div class="text-center">
        <img alt="Business Model Canvas" className="img-responsive" src={bmc}/>
    </div>

    <p>
        Pour les ressources en personnel, ce projet fait appel à un réseau de partenaires autorisés par le SECO, à pratiquer la location de services et/ou le placement de personnel. 
        <br/>Le réseau d'apporteurs d'affaires et résauteurs, utilise des outils et des plateformes, en mode SaaS, mise à disposition et développés par des ressources locales.
    </p>
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <p class="copyright text-muted small">Copyright &copy; myBusinessPartner 2023 - {currentYear()} <span class="text-nowrap">All Rights Reserved</span></p>
                </div>
            </div>
        </div>
    </footer>

</div>

        )


        }