import {Fragment, useState} from 'react'

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/Badge';
import NavBar from './NavBar';
import Services from './Services';
import Welcome from './Welcome';
import Contact from './Contact';

import './App.css'

export default function App() {

    const [option, setOption] = useState(0);

    switch (option) {

        case 0:
            return (
                    <Fragment>
                    
                        <NavBar setOption={setOption}/>
                    
                        <Welcome />
                    
                    </Fragment>
                    )
            break;
        case 1:
            return (
                    <Fragment>
                    
                        <NavBar setOption={setOption}/>
                    
                        <Services/> 
                    
                    </Fragment>
                    )
            break;
        case 4:
            return (
                    <Fragment>
                    
                        <NavBar setOption={setOption}/>
                    
                        <Contact/> 
                    
                    </Fragment>
                    )

        default:
            return (<div></div>)

    }
}

