import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from './pics/logo150.png';

import Image from 'react-bootstrap/Image'

export default function NavBar(props) {
        
  return (
       <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
      <Nav><img src={logo}/></Nav>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="">
            <Nav.Link className="navbar-left" onClick={()=>props.setOption(0)}>Home</Nav.Link>
            <Nav.Link onClick={()=>props.setOption(1)}>Qui sommes nous&nbsp;?</Nav.Link>
            <Nav.Link onClick={()=>props.setOption(4)}>Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  
  );
            
     
}

/*
 * 
 * 
    <NavDropdown title="Autre" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">A venir</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">A venir</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">A venir</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={()=>props.setOption(4)}>
               Contact
              </NavDropdown.Item>
            </NavDropdown>
 */