
import Card from 'react-bootstrap/Card';

function currentYear() {
    return new Date().getFullYear();
}

export default function Contact() {
    return (
            <div>
                <div style={{margin: '20px', marginTop: '60px'}}>
                    <Card style={{padding: '20px', maxWidth: '400px', width: '100%', height: '200px', margin: 'auto', }} className='text-center' border='danger'>
                    <p>Projet "myBusinessPartner"</p>
                    <p>Section romandie</p>
                    <p><i className="bi bi-telephone"></i>+41 21 657 13 50 (répondeur)</p>
                    <p><i className="bi bi-telephone"></i>&#119;&#101;&#108;&#99;&#111;&#109;&#101;(at)mybusinesspartner.ch</p>
                    </Card>
                </div>
            
                <footer>
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
            
                                <p class="copyright text-muted small">Copyright &copy; myBusinessPartner 2023 - {currentYear()} <span class="text-nowrap">All Rights Reserved</span></p>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>

            )
}